import { flatten, startCase } from 'lodash'
import { Badge } from 'maple-storybook'
import { AccessTime } from 'maple-storybook/assets'
import moment from 'moment'

import { capitalize, checkArrayPresence } from './applicationHelper'
import { checkPermission } from './permissionsHelper'
import { usersStatusOptions } from './userHelper'

const closeOperation = () => {
  return <Badge size="md" label={<>&#8226; Closure</>} className="claim-status-field closure-status" />
}

export const PENDING_CLAIM_STATUSES = [
  'pending_pm_approval',
  'pending_lead_approval',
  'processing_bill',
  'pending_departmental_approval'
]

export const showRejectButton = (claim) =>
  (claim.parent_id !== null ||
    claim.end_date === null ||
    claim.operation !== 'closure' ||
    claim.closed_by.name !== 'System' ||
    !PENDING_CLAIM_STATUSES.includes(claim.status)) &&
  !claim?.is_auto_closure &&
  !claim?.is_redeemed

export const selectStatusesforAdmin = [
  { label: 'Pending(Lead)', value: 'pending_lead_approval' },
  { label: 'Pending(PM)', value: 'pending_pm_approval' },
  { label: 'Pending(Department)', value: 'pending_departmental_approval' },
  { label: 'Processing Bill', value: 'processing_bill' }
]

export const confirmationText = (action, htmlText) => {
  if (action === 'approve' && htmlText === 'Payment Verified') {
    return 'Mark this claim as "Received"'
  } else if (action === 'approve' && htmlText === 'Pay') {
    return 'Mark this claim as "Paid"'
  } else {
    return `Are you sure you want to ${action} this Claim?`
  }
}

export const nonCommentableClaimStatuses = ['paid', 'payment_verified']

export const renderStatus = (status, operation) => {
  if (status === 'rejected')
    return (
      <Badge
        size="md"
        label={
          <>
            <span className="status-dotted"></span> REJECTED
          </>
        }
        className="claim-status-field rejected"
      />
    )
  else if (status === 'paid')
    return (
      <Badge
        size="md"
        label={
          <>
            <span className="status-dotted"></span> PAID
          </>
        }
        className="claim-status-field paid"
      />
    )
  else if (status === 'payment_verified')
    return (
      <Badge
        size="md"
        label={
          <>
            <span className="status-dotted"></span> PAYMENT VERIFIED
          </>
        }
        className="claim-status-field payment_verified"
      />
    )
  else if (status === 'not_received')
    return (
      <Badge
        size="md"
        label={
          <>
            <span className="status-dotted"></span> NOT RECEIVED
          </>
        }
        className="claim-status-field not_received"
      />
    )
  else if (status === 'to_be_adjusted')
    return (
      <Badge
        size="md"
        label={
          <>
            <span className="status-dotted"></span> TO BE ADJUSTED
          </>
        }
        className="claim-status-field"
        style={{ backgroundColor: '#AAACCC', color: '#A76565' }}
      />
    )
  else if (status === 'processing_bill')
    return (
      <span className={operation === 'closure' ? 'flex-column' : ''}>
        <Badge
          size="md"
          label={
            <>
              <span className="status-dotted"></span> PROCESSING BILL
            </>
          }
          className="claim-status-field processing_bill"
        />
        {operation === 'closure' && closeOperation()}
      </span>
    )
  else if (status === 'processing_payment')
    return (
      <Badge
        size="md"
        label={
          <>
            <span className="status-dotted"></span> PROCESSING PAYMENT
          </>
        }
        className="claim-status-field processing_payment"
      />
    )
  else if (status === 'processed')
    return (
      <Badge
        size="md"
        label={
          <>
            <span className="status-dotted"></span> PROCESSED
          </>
        }
        className="claim-status-field processed"
        style={{ backgroundColor: '#09C53E', color: '#02651E' }}
      />
    )
  else if (status === 'pending_lead_approval')
    return (
      <span className={operation === 'closure' ? 'flex-column' : ''}>
        <Badge
          size="md"
          label={
            <>
              <span className="status-dotted"></span> PENDING(LEAD)
            </>
          }
          className="claim-status-field pending-lead-status"
        />
        {operation === 'closure' && closeOperation()}
      </span>
    )
  else if (status === 'pending_pm_approval')
    return (
      <span className={operation === 'closure' ? 'flex-column' : ''}>
        <Badge
          size="md"
          label={
            <>
              <span className="status-dotted"></span> PENDING(PM)
            </>
          }
          className="claim-status-field pending-pm-status"
        />
        {operation === 'closure' && closeOperation()}
      </span>
    )
  else if (status === 'pending_departmental_approval')
    return (
      <span className={operation === 'closure' ? 'flex-column' : ''}>
        <Badge
          size="md"
          label={
            <>
              <span className="status-dotted"></span> Pending Approval Dept
            </>
          }
          className="claim-status-field pending-departmental-status"
        />
        {operation === 'closure' && closeOperation()}
      </span>
    )
  else if (status === 'closed')
    return (
      <Badge
        size="md"
        label={
          <>
            <span className="status-dotted"></span> CLOSED
          </>
        }
        className="claim-status-field close-status"
      />
    )
  else if (status === 'continued')
    return (
      <Badge
        size="md"
        label={
          <>
            <span className="status-dotted"></span> CONTINUED
          </>
        }
        className="claim-status-field continue-status"
      />
    )
  else if (status === 'awaiting_discontinuation')
    return (
      <div className={operation === 'closure' ? 'flex-column' : ''}>
        <Badge
          size="md"
          label={<>&#8226; Awaiting Discontinuation</>}
          className="claim-status-field awaiting-discontinuation-status"
        />
        <Badge size="md" label={<>&#8226; Closure</>} className="claim-status-field closure-status" />
      </div>
    )
  else if (status === 'discontinued')
    return <Badge size="md" label={<>&#8226; DISCONTINUED</>} className="claim-status-field discontinued-status" />
  else if (status === 'cancelled')
    return <Badge size="md" label={<>&#8226; Cancelled</>} className="claim-status-field cancel-status" />
  else if (status === 'pending_child')
    return <Badge size="md" label={<>&#8226; Pending Child</>} className="claim-status-field pending-child-status" />
}
export const subTabs = [
  {
    id: 0,
    label: 'Non Recurring Claims',
    value: 'nonRecurringClaims'
  },
  {
    id: 1,
    label: 'Recurring Claims',
    value: 'recurringClaims'
  },
  {
    id: 2,
    label: 'Third Party Bills',
    value: 'third_party_bills'
  }
]

export const claimDependentsList = [
  { label: 'Joint Max Limit', value: 1 },
  { label: 'Exclusivity', value: 2 }
]

export const statusText = (status) => {
  if (status === 'rejected') return 'Rejected'
  else if (status === 'paid') return 'Paid'
  else if (status === 'payment_verified') return 'Payment Verified'
  else if (status === 'not_received') return 'Not Received'
  else if (status === 'to_be_adjusted') return 'To Be Adjusted'
  else if (status === 'pending_lead_approval') return 'Pending Approval(LEAD)'
  else if (status === 'pending_pm_approval') return 'Pending Approval(PM)'
  else if (status === 'pending_departmental_approval') return 'Pending Approval(DEPT)'
  else if (status === 'processing_bill') return 'Processing Bill'
  else if (status === 'processing_payment') return 'Processing Payment'
  else if (status === 'processed') return 'Processed'
  else if (status === 'closed') return 'Closed'
  else if (status === 'continued') return 'Continued'
  else if (status === 'awaiting_discontinuation') return 'Awaiting Discontinuation'
  else if (status === 'discontinued') return 'Discontinued'
  else if (status === 'cancelled') return 'Cancelled'
  else if (status === 'pending_child') return 'Pending Child'
}

export const getStatusCallbackText = (status) => {
  if (status === 'rejected') return 'Rejected'
  else if (status === 'paid') return 'Mark Received'
  else if (status === 'payment_verified') return 'Payment Verified'
  else if (status === 'pending_lead_approval') return 'Approve'
  else if (status === 'pending_pm_approval') return 'Approve'
  else if (status === 'pending_departmental_approval') return 'Approve'
  else if (status === 'to_be_adjusted') return 'Mark Processed'
  else if (status === 'processing_payment') return 'Approve'
  else if (status === 'processing_bill') return 'Approve'
  else if (status === 'processed') return 'Pay'
  else if (status === 'closed') return 'Closed'
  else if (status === 'pending_child') return 'Pending Child'
}

export const claimStatusTimelineText = (status, dynamic_approval_title) => {
  if (status === 'pending_lead_approval') return 'Lead'
  else if (status === 'pending_pm_approval') return 'Project Manager'
  else if (status === 'pending_departmental_approval')
    return `Department ${dynamic_approval_title ? `(${dynamic_approval_title})` : ''}`
  else return startCase(status)
}

export const pendingStatuses = ['pending_lead_approval', 'pending_pm_approval', 'pending_departmental_approval']

export const claimStatusTimelineAction = (status, nextStatus, operation) => {
  if (nextStatus === 'rejected') return 'Rejected'
  else if (status === 'paid') return 'Processed'
  else if (
    pendingStatuses.includes(status) &&
    (pendingStatuses.includes(nextStatus) ||
      nextStatus === 'processing_bill' ||
      nextStatus === 'awaiting_discontinuation' ||
      nextStatus === 'processing_payment')
  )
    return 'Approved'
  else if (
    [...pendingStatuses, 'processing_bill', 'processing_payment', 'awaiting_discontinuation'].includes(status) &&
    nextStatus === ''
  )
    return 'Pending'
  else if (['processing_bill', 'processing_payment'].includes(status) && nextStatus !== '') return 'Processed'
  else if (status === 'processing_bill') {
    if (nextStatus === '' && operation === 'closure') return 'Awaiting_discontinuation'
    else if (nextStatus === '') return 'Processing'
    else if (nextStatus === 'awaiting_discontinuation') return 'Discontinuation'
  } else if (status === 'awaiting_discontinuation' && nextStatus === 'discontinued') return 'Discontinued'
}

export const expectedStatuses = [
  'pending_lead_approval',
  'pending_pm_approval',
  'pending_departmental_approval',
  'processing_bill',
  'processing_payment',
  'paid',
  'payment_verified',
  'continued',
  'discontinued',
  'awaiting_discontinuation',
  'cancelled',
  'closed'
]

export const finalStatuses = ['payment_verified', 'discontinued', 'cancelled', 'closed', 'paid']

export const formatArray = (data, value, label, captalize = false) => {
  let formatedArray = []

  if (data.length !== 0) {
    data.forEach(function (record, _id) {
      if (captalize) {
        formatedArray.push({ value: record[value], label: capitalize(record[label]) })
      } else {
        formatedArray.push({ value: record[value], label: record[label] })
      }
    })
  }

  return formatedArray
}

export const claimStatus = [
  'pending_lead_approval',
  'pending_pm_approval',
  'pending_departmental_approval',
  'processing_bill',
  'processing_payment',
  'processed',
  'rejected',
  'not_received',
  'to_be_adjusted',
  'closed',
  'paid',
  'payment_verified',
  'cancelled',
  'pending_child'
]

export const formatClaimsDates = (claimsList) =>
  flatten(
    claimsList.map((claim) => [
      {
        ...claim,
        date_formatted: moment(claim.date_formatted, 'DD MMM YYYY', true).isValid()
          ? claim.date_formatted
          : moment(claim.date_formatted).format('DD MMM YYYY'),
        created_at_formatted: moment(claim.created_at_formatted, 'DD MMM YYYY', true).isValid()
          ? claim.created_at_formatted
          : moment(claim.created_at_formatted).format('DD MMM YYYY')
      }
    ])
  )

export const formatClaimDate = (claimDetail) => ({
  ...claimDetail,
  start_date: moment(claimDetail.start_date).format('Do MMMM YYYY'),
  created_at: moment(claimDetail.created_at).format('Do MMMM YYYY')
})

export const fetchClaimsHelper = (tabValue, searchParams) => {
  let searchParameters = searchParams
  if (tabValue === 'recurringClaims') {
    searchParameters.recurring = true
    searchParameters.third_party_bills = false
    searchParameters.nested = true
    searchParameters.statuses.push(3, 5, 12, 13, 14, 17)
  } else if (tabValue === 'nonRecurringClaims') {
    searchParameters.recurring = false
    searchParameters.third_party_bills = false
    searchParameters.nested = true
    searchParameters.parent_id = null
    searchParameters.statuses = searchParameters.statuses.filter((value) => ![12, 13, 14, 17].includes(value))
    searchParameters.statuses.push(3, 5, 9)
  } else if (tabValue === 'third_party_bills') {
    searchParameters.recurring = false
    searchParameters.nested = false
    searchParameters.parent_id = null
    searchParameters.third_party_bills = true
    searchParameters.claim_types = []
    searchParameters.statuses = searchParameters.statuses.filter(
      (value) => !['0', 1, 8, 11, 12, 13, 14, 17].includes(value)
    )
    searchParameters.statuses.push(3, 5, 9)
  } else if (tabValue === null) {
    searchParameters.recurring = null
    searchParameters.third_party_bills = false
    searchParameters.nested = true
  }
  searchParameters.statuses = [...new Set(searchParameters.statuses)]
  return searchParameters
}

export const formatClaimTime = (claimTime) => {
  claimTime = claimTime.split(':').map((time) => parseInt(time, 10))
  if (claimTime === undefined) {
    return 'invalid time'
  }

  return `${claimTime[0]}h ${claimTime[1]}m`
}

export const buildSearchParams = (params) => {
  let searchParams = {}

  if (checkArrayPresence(params.claim_types)) {
    searchParams = Object.assign({ claim_types: params.claim_types }, searchParams)
  }
  if (checkArrayPresence(params.statuses)) {
    searchParams = Object.assign({ statuses: params.statuses }, searchParams)
  }
  if (checkArrayPresence(params.created_by)) {
    searchParams = Object.assign({ created_by: params.created_by }, searchParams)
  }
  if (params.start_date) {
    searchParams = Object.assign({ start_date: params.start_date }, searchParams)
  }
  if ('nested' in params) {
    searchParams = Object.assign({ nested: params.nested }, searchParams)
  }
  if (params.parent_id > 0) {
    searchParams = Object.assign({ parent_id: params.parent_id }, searchParams)
  }
  if (params.end_date) {
    searchParams = Object.assign({ end_date: params.end_date }, searchParams)
  }
  if ('recurring' in params) {
    searchParams = Object.assign({ recurring: params.recurring }, searchParams)
  }
  if (params.approvals) {
    searchParams = Object.assign({ approvals: params.approvals }, searchParams)
  }
  if (params.tab) {
    searchParams = Object.assign({ tab: params.tab }, searchParams)
  }
  if (checkArrayPresence(params.claim_ids) && params.claim_ids[0] !== '') {
    searchParams = Object.assign({ claim_ids: params.claim_ids }, searchParams)
  }
  if (checkArrayPresence(params.project_id)) {
    searchParams = Object.assign({ project_id: params.project_id }, searchParams)
  }
  if (checkArrayPresence(params.thread_ids)) {
    searchParams = Object.assign({ thread_ids: params.thread_ids }, searchParams)
  }
  if (checkArrayPresence(params.location_ids)) {
    searchParams = Object.assign({ location_ids: params.location_ids }, searchParams)
  }

  return searchParams
}

export const statusColor = (status) => {
  if (status === 'rejected') return { backgroundColor: '#F78282', color: '#902424' }
  else if (status === 'reimbursed') return { backgroundColor: '#454343', color: 'white' }
  else if (status === 'received') return { backgroundColor: '#7CC8FF', color: '#3676A5' }
  else if (status === 'not_received') return { backgroundColor: '#FFB7B7', color: '#A76565' }
  else if (status === 'to_be_adjusted') return { backgroundColor: '#AAACCC', color: '#A76565' }
  else if (status === 'processing_bill') return { backgroundColor: '#1AE4D8', color: '#02726B' }
  else if (status === 'processing_payment') return { backgroundColor: '#1AE49B', color: '#02651E' }
  else if (status === 'processed') return { backgroundColor: '#09C53E', color: '#02651E' }
  else if (status === 'pending_lead_approval') return { backgroundColor: '#F27404', color: '#A52002' }
  else if (status === 'pending_pm_approval') return { backgroundColor: '#F2B704', color: '#8B5300' }
  else if (status === 'pending_departmental_approval') return { backgroundColor: '#FFD706', color: '#A98F08' }
  else if (status === 'continued') return { backgroundColor: '#09C53E', color: '#02651E' }
  else if (status === 'awaiting_discontinuation') return { backgroundColor: '#FFB800', color: '#BE5900' }
  else if (status === 'discontinued') return { backgroundColor: '#F68282', color: '#902424' }
  else if (status === 'payment_verified') return { backgroundColor: '#7CC8FF', color: '#3676A5' }
  else if (status === 'paid') return { backgroundColor: '#BDBDBD', color: '#454343' }
  else if (status === 'closed') return { backgroundColor: '#F73131' }
  else if (status === 'cancelled') return { backgroundColor: '#FF7D60', color: '#9A0B0B' }
  else if (status === 'pending_child') return { backgroundColor: '#F2B704', color: '#8B5300' }
}

export const ClaimsTimeTracker = ({ time, label, color }) => (
  <div className="claims-time-tracker-div d-flex flex-column align-items-center">
    <div className="claim-time-tracker d-flex">
      <div className="time-tracker-icon">
        <AccessTime style={{ color: color }} />
      </div>
      <p>{time ? formatTrackedTime(time) : '00:00'}</p>
    </div>
    <p>
      <b>{label}</b>
    </p>
  </div>
)

const formatTrackedTime = (time) => {
  const hours = time.split('h')
  const mins = hours[1].split('m')

  return `${addLeadingZero(hours[0].trim())}:${addLeadingZero(mins[0].trim())}`
}

const addLeadingZero = (count) => (count < 10 ? '0' + count : count)

export const checkClaimCount = (claimStats) => {
  let counts = 0
  Object.values(claimStats).forEach((total) => {
    counts += total
  })
  return counts
}

export const remainingAmountWithAttendees = (currentClaimType, claim) => {
  let sum = 0
  if (currentClaimType) {
    sum =
      (currentClaimType.amount_limit / 100) * (claim?.attendees?.length || 1) - currentClaimType.claimed_amount / 100
    if (sum == 0 && currentClaimType.multiply_amount_limit && currentClaimType.allow_participants) {
      sum = 1
    }
  }

  return sum
}

export const amountRestrictionTypeOptions = (project, disableFixedPercentageButton) => {
  let options = [
    { label: 'Max amount limit', value: 'max_limit' },
    { label: 'Fixed amount', value: 'fix_limit' },
    { label: 'No restriction', value: 'no_limit' }
  ]
  if (project)
    options.push({
      label: 'Fixed Percentage',
      value: 'fixed_percentage',
      isOptionDisabled: disableFixedPercentageButton
    })
  return options
}

export const maxAmountLimitOptions = [
  { label: 'Per claim', value: 'per_claim_limit' },
  { label: 'Per month', value: 'monthly_limit' },
  { label: 'Per quarter', value: 'quarterly_limit' },
  { label: 'Bi-annual', value: 'biannual_limit' },
  { label: 'Annual', value: 'annual_limit' }
]

export const prorationTypeOptions = [
  { label: 'Full', value: 'full_prorated' },
  { label: 'Partial', value: 'partial_prorated' }
]

export const claimsTabs = [
  {
    value: '/claims/approvals',
    label: 'Claim Approvals',
    href: '/claims/approvals'
  },
  {
    value: '/claims/management',
    label: 'Claim Management',
    href: '/claims/management'
  }
]

export const can_access_claim_management = (userPermissions) =>
  checkPermission(userPermissions, 'claims', 'general', 'admin_view_all_claims').isFull() ||
  checkPermission(userPermissions, 'claims', 'general', 'admin_view_all_claims').isSubordinate() ||
  checkPermission(userPermissions, 'claims', 'general', 'admin_view_all_claims').isDynamicApprovalReporting()

export const isClaimsTabs = (path) => ['/claims/approvals', '/claims/management'].includes(path) || false

export const isNewForm = (index, isEdit, prevFormValues, formValues) =>
  (index < prevFormValues?.amount_settings?.length ||
    (formValues?.amount_settings[index] === prevFormValues?.amount_settings[index] &&
      typeof formValues.amount_settings[index] !== 'undefined')) &&
  isEdit

export const renderClaimsActiveTab = (permissions) => {
  const tabsPermissions = [
    checkPermission(permissions, 'claims', 'general', 'view_claim_approvals').isFull(),
    can_access_claim_management(permissions)
  ]
  const activeTab = tabsPermissions.indexOf(true)
  return claimsTabs[activeTab].href
}

export const carousalSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: 0,
  autoplaySpeed: 2500,
  cssEase: 'linear',

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        infinite: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ],
  appendDots: (dots) => (
    <div className="settings-append-dots">
      <ul className="list"> {dots} </ul>
    </div>
  ),
  customPaging: (i) => <div className="settings-custom-paging">{i + 1}</div>
}

export const modifiedCarousalSettings = (attachments) => {
  return {
    ...carousalSettings,
    dots: true,
    infinite: attachments?.length > 3,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  }
}

export const claimsStatusOptions = [
  { label: 'Awaiting Discontinuation', value: 13 },
  { label: 'Cancelled', value: 17 },
  { label: 'Closed', value: 9 },
  { label: 'Continued', value: 12 },
  { label: 'Discontinued', value: 14 },
  { label: 'Not Received', value: 6 },
  { label: 'Paid', value: 4 },
  { label: 'Payment Verified', value: 5 },
  { label: 'Pending Child', value: 18 },
  { label: 'Pending(Dept)', value: 8 },
  { label: 'Pending(Lead)', value: 1 },
  { label: 'Pending(PM)', value: '0' },
  { label: 'Processed', value: 10 },
  { label: 'Processing Bill', value: 2 },
  { label: 'Processing Payment', value: 7 },
  { label: 'Rejected', value: 3 },
  { label: 'To Be Adjusted', value: 11 }
]

export const claimStatusOptionsForClaimApprovals = [
  { label: 'Pending(Dept)', value: 8 },
  { label: 'Pending(Lead)', value: 1 },
  { label: 'Pending(PM)', value: '0' },
  { label: 'Processed', value: 10 },
  { label: 'Processing Bill', value: 2 },
  { label: 'To Be Adjusted', value: 11 }
]

export const creatorSearchFilters = {
  statuses: usersStatusOptions.map((userStatus) => userStatus.value).concat('active')
}

export const claimApprovalFilters = [
  {
    label: 'Category',
    value: 'claim_types',
    component: 'selectWrapper',
    placeholder: 'Select Category(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Status',
    value: 'statuses',
    component: 'selectWrapper',
    placeholder: 'Select Status(s)',
    multiOption: true,
    allCheck: true
  },
  {
    label: 'Creator',
    value: 'created_by',
    component: 'selectWrapper',
    placeholder: 'Search by Creator(s)',
    userSearchable: true,
    multiOption: true,
    optionFilters: { creatorSearchFilters }
  },
  {
    label: 'Date Range',
    value: 'custom_date_range',
    fromDateValue: 'start_date',
    toDateValue: 'end_date',
    component: 'dateRangePickerWrapper'
  },
  {
    label: 'Location',
    value: 'location_ids',
    component: 'selectWrapper',
    placeholder: 'Select Location(s)',
    multiOption: true,
    allCheck: true
  }
]

export const claimManagementFilters = (params, customSetter) => {
  let filters = [
    {
      label: 'Status',
      value: 'statuses',
      component: 'selectWrapper',
      placeholder: 'Select Status(s)',
      multiOption: true,
      allCheck: true
    },
    {
      label: 'Creator',
      value: 'created_by',
      component: 'selectWrapper',
      placeholder: 'Search by Creator(s)',
      userSearchable: true,
      multiOption: true,
      optionFilters: { creatorSearchFilters }
    },
    {
      label: 'Date Range',
      value: 'custom_date_range',
      fromDateValue: 'start_date',
      toDateValue: 'end_date',
      component: 'dateRangePickerWrapper'
    },
    {
      label: 'Location',
      value: 'location_ids',
      component: 'selectWrapper',
      placeholder: 'Select Location(s)',
      multiOption: true,
      allCheck: true
    }
  ]
  if (params.subTab !== 'third_party_bills')
    filters = [
      {
        label: 'Category',
        value: 'claim_types',
        component: 'selectWrapper',
        placeholder: 'Select Category(s)',
        multiOption: true,
        allCheck: true
      },
      ...filters
    ]
  if (params.subTab === 'recurringClaims') {
    filters.push({
      label: 'Project',
      placeholder: 'Select Project',
      nestedSelection: true,
      selectedValue: customSetter.selectedProject,
      setValue: customSetter.setSelectedProject,
      component: 'selectWrapper',
      value: 'project_id',
      nestedOptions: [
        {
          label: 'Thread',
          value: 'thread_ids',
          component: 'selectWrapper',
          placeholder: 'Select Thread',
          conditionToRender: customSetter.selectedProject
        }
      ]
    })
  }
  if (params.subTab === 'third_party_bills') {
    filters.push({
      label: 'Vendor',
      value: 'vendors',
      component: 'selectWrapper',
      placeholder: 'Select Vendor(s)',
      multiOption: true,
      allCheck: true
    })
  }
  return filters
}
