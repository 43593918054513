import { toastr } from 'react-redux-toastr'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { replace, startCase } from 'lodash'
import { Tooltip } from 'maple-storybook'
import { InfoRounded } from 'maple-storybook/assets'
import {
  checkArrayEmpty,
  checkArrayPresence,
  collectionIntoSelectOptions,
  totalItems,
  webView
} from 'maple-storybook/utils'
import moment from 'moment'
import CustomToastr from 'views/Toastr/CustomToastr'

import { AMS_PORT } from 'config/apiConfig'
import { DATE_FORMAT, EMAIL_FORMAT } from 'helpers/constants'

import * as TimeAgoConfig from '../config/timeAgoConfig'

import { AMS_IFRAME_ID } from './constants'
import { editOtherProfile, invitePeople, isAccountOwner } from './userProfileHelper'

export {
  checkPermission,
  checkValueNull,
  checkValueType,
  collectionIntoSelectOptionsWithImages,
  emptyFilterTypeAndValue,
  extractDefaultPermissions,
  flattenObj,
  formatLongText,
  getFiltersClearValue,
  getUserOnBoardingID,
  isNumber,
  onBoardingUserID,
  trimmedLength
} from 'maple-storybook/utils'

export { checkArrayEmpty, checkArrayPresence, collectionIntoSelectOptions, totalItems, webView }

export const errorsAlert = ({ errors, trace_id }, default_msg = null) => {
  if (trace_id) {
    toastr.error(errors[0], {
      timeOut: 10000,
      component: <CustomToastr errors={errors} trace_id={trace_id} />
    })
  } else {
    errors
      ? typeof errors === 'object'
        ? errors.map((error) => toastr.error(error))
        : toastr.error(errors)
      : toastr.error(default_msg)
  }
}

export const concatNotApplicableOption = (options) =>
  [{ label: 'Not Applicable', value: '0', avatar: null }].concat(options)

export const pseudoProfileText = (title, id, timeline = false) => `${title} (PPID-${id}) ${timeline ? 'Thread' : ''}`

export const invalidTextLength = (value) => value.length < 3 && value.length > 0

export const validTextLength = (value, displayHint) => (value.length >= 3 || value.length === 0) && displayHint

export const isImpersonateMode = () => localStorage.getItem('impersonate') === 'true'

export const warningAlert = (warnings) => {
  if (warnings) {
    typeof warnings === 'object' ? warnings.map((warning) => toastr.warning(warning)) : toastr.warning(warnings)
  }
}

export const DOMAIN = window.location.hostname.split('.')[0]

export const timesAgo = (date) => {
  TimeAgo.locale(en)
  const timeAgo = new TimeAgo('en-US')
  return startCase(timeAgo.format(new Date(date), TimeAgoConfig.timeStyle))
}

export const intervalToSeconds = (interval) => {
  let parts = interval.split(':')
  return parseInt(parts[0]) * 60 * 60 + parseInt(parts[1]) * 60
}

export const capitalize = (string) => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const fullUserName = (firstName, lastName) => {
  return firstName + ' ' + lastName
}

export const sortValue = (value) => value.sort((a, b) => a.label.localeCompare(b.label))

export const formatObjectIntoSelectValue = (label, value) => ({ label: label, value: value })

export const addClass = (parent, newClass) => elementByClass(parent) && elementByClass(parent).classList.add(newClass)

export const addClassOnTag = (parent, newClass) => document.getElementsByTagName(parent)[0].classList.add(newClass)

export const removeClass = (parent, oldClass) =>
  elementByClass(parent) && elementByClass(parent).classList.remove(oldClass)

export const removeClassOnTag = (parent, newClass) =>
  document.getElementsByTagName(parent)[0].classList.remove(newClass)

const elementByClass = (parent) => document.getElementsByClassName(parent)[0]

export const toggleClass = (parent, className, flag) =>
  flag === true ? addClass(parent, className) : removeClass(parent, className)

export const allowedBirthDate = () =>
  new Date(new Date().getFullYear() - 16, new Date().getMonth(), new Date().getDate())

export const formatErrors = (errors) => {
  let formatedErrors = errors.includes('Validation failed') ? errors.substring(19).split(',') : errors.split(',')
  formatedErrors = formatedErrors.map((formatedError) => formatedError.trim())
  return formatedErrors
}

export const generateUUID = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (value) =>
    //eslint-disable-next-line
    (value ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (value / 4)))).toString(16)
  )
}

export const secondsToInterval = (seconds) => {
  return new Date(1000 * seconds).toISOString().substr(11, 5)
}

export const formatDate = (date) => {
  return moment(new Date(date)).format(DATE_FORMAT)
}

export const secondsToHoursAndMinutes = (seconds) => {
  let hours = parseInt(seconds / 3600)
  let minutes = parseInt((seconds / 3600 - hours) * 60)
  return `${hours}h ${minutes} m`
}

export const handleNotApplicableValue = (input, label) => {
  if (label && Array.isArray(input.value) && input.value.filter((option) => option.label === label).length > 0) {
    input.value = input.value.filter((option) => option.label === label)[0]
  }
}

export const extractFormKeys = (obj, prefix = '') =>
  Object.keys(obj).reduce((arr, key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (!isNaN(key)) {
        return [...arr, ...extractFormKeys(obj[key], `${prefix}[${key}].`)]
      }
      return [...arr, ...extractFormKeys(obj[key], prefix + key)]
    }
    return [...arr, prefix + key]
  }, [])

export const convertToLowerCase = (text) => text.toLowerCase()

export const replaceSpaceWithHyphen = (text) => text.replace(/\s/g, '-')

export const replaceUnderscoreWithHyphen = (text) => text.replace(/_/g, '-')

export const uniqueArrayValues = (value, index, array) => array.indexOf(value) === index

export const fileSizeConverter = (bytes) => {
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
}

export const paginationItemsCount = (pageSize) => Math.ceil(totalItems() / pageSize + 1) - 1

export const addHyphenToCnicNumber = (number) =>
  [number.substring(0, 5), number.substring(5, 12), number.substring(12, number.length)].join('-')

export const removeHyphenToPhoneNumber = (number) => number.replace(/-/g, '')

export const addHyphenToPhoneNumber = (number) => {
  number = removeHyphenToPhoneNumber(number)
  return [number.substring(0, 4), number.substring(4, number.length)].join('-')
}

export const customRedirectHelper = (url) => {
  if (url.includes('claims/?')) {
    return replace(url, 'claims/?', 'claims?')
  } else if (url.includes('/claims/?approval/')) {
    return replace(url, '/claims/?approval/', '/claims/approvals?approval/')
  } else {
    return url
  }
}

export const removeProfilePartFromURL = (url) => {
  let updatedURL = ''
  if (url.includes('tickets/')) {
    updatedURL = url.replace(/\/profile\/\d+\/tickets\//, '/ticket/')
    return updatedURL.replace(/tickets\//g, 'ticket/')
  } else {
    return url.replace(/\/profile\/\d+\/tickets/, '/tickets')
  }
}

export const extractPath = (url) => {
  let updatedUrl = ''
  if (url.includes('tickets') && url.includes('profile')) {
    updatedUrl = removeProfilePartFromURL(url)
  } else {
    updatedUrl = url
  }
  const newURL = customRedirectHelper(updatedUrl)
  const splittedURL = newURL.split('/')

  let extractedURL = ''
  splittedURL.map(
    (value, index) =>
      index + 3 < splittedURL.length && (extractedURL = extractedURL.concat(`${splittedURL[index + 3]}/`))
  )
  return extractedURL
}

export const currencyOptions = [
  { label: 'USD', value: 'usd' },
  { label: 'PKR', value: 'pkr' }
]

export const wordsAcronym = (text) => text.split(/\s/).reduce((accumulator, word) => accumulator + word.charAt(0), '')

export const tabsPermissionRedirection = (tabsPermissions, allTabs, setCurrentTab, basePath) => {
  if (!tabsPermissions[0] && window.location.pathname === basePath) {
    for (let index = 0; index < tabsPermissions.length; index++) {
      if (tabsPermissions[index] && allTabs[index].href !== window.location.pathname) {
        setCurrentTab(allTabs[index].href)
        window.history.pushState(null, '', `${allTabs[index].href}`)
        break
      } else if (tabsPermissions[index] && allTabs[index].href === window.location.pathname) {
        break
      }
    }
  }
}

export const formatLevelsIntoOptions = (titleState) =>
  collectionIntoSelectOptions(
    titleState.allTitles.filter((level) => level.parent_id),
    'id',
    'name'
  )

export const formatTitlesIntoOptions = (titleState) =>
  titleState.allTitles.map((title) => ({
    ...title,
    value: title.id,
    label: title.name,
    department_id: title.department_id
  }))

export const filteredTitles = (selectedDepartment, designations) =>
  checkArrayPresence(selectedDepartment)
    ? designations.filter((title) => selectedDepartment.includes(title.department_id))
    : designations

export const formatObjectIntoOptions = (employmentTypes) =>
  Object.values(employmentTypes).map((empType) => ({ value: empType, label: transformString(empType) }))

export const transformString = (string) => string.humanize().titleize()

export const getDefaultPageSize = () => (webView() ? 50 : 10)

export const labelWithInfoIcon = (label, infoText) => {
  return (
    <>
      {label}
      <Tooltip title={infoText} place="right" effect="solid">
        <InfoRounded fontSize="10" className="ml-half info-icon" />
      </Tooltip>
    </>
  )
}

export const scrollIntoViewHelper = (errors) => {
  const firstError = errors[0]
  let selectedField = ''
  if (document.querySelector(`[name="${firstError}"]`)?.className?.includes('MuiInputBase-input')) {
    selectedField = document.querySelector(`[name="${firstError}"]`)
  } else {
    selectedField = document.querySelector(`[id="${firstError}"]`)
  }

  if (selectedField) {
    selectedField.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }
}

export const handleSelectAllItems = (
  totalItems,
  setCurrentItems,
  allSelected,
  setAllSelected,
  errorMessage = 'No Items to Select'
) => {
  let newItems = []
  if (allSelected) {
    setAllSelected(false)
  } else {
    if (checkArrayEmpty(totalItems)) errorsAlert(errorMessage)
    else {
      totalItems.forEach(({ id }) => newItems.push(id))
      setAllSelected(true)
    }
  }
  setCurrentItems(newItems)
}

export const handleSingleSelectItem = (id, selectedItems, setAllSelected, totalItems, setCurrentItems) => {
  let newItems = [...selectedItems]
  if (newItems.includes(id)) {
    newItems = newItems.filter((item) => item !== id)
    setAllSelected(false)
  } else {
    newItems.push(id)
    if (newItems.length === totalItems.length) {
      setAllSelected(true)
    } else {
      setAllSelected(false)
    }
  }
  setCurrentItems(newItems)
}

export const genericApprovalOptions = [
  { value: 1, label: 'Approved' },
  { value: '0', label: 'Pending' },
  { value: 2, label: 'Rejected' }
]

export const bloodGroupFormat = (input) => input.replace(/_negative/g, ' -').replace(/_positive/g, ' +')

export const generateNavigationString = (id, array) => {
  return `${array.indexOf(id) + 1} / ${array.length}`
}

export const achievedPercentage = (total, completed) => ((total - completed) / total) * 100

export const achievedTotal = (total, completed) => `${total - completed}/${total}`

export const paidFeatureText = (feature, companyName) => `No ${feature} can be added as ${companyName} is on free plan`

export const findStrings = (errors, rowNumber) => {
  if (!Array.isArray(errors)) {
    return [`row #${rowNumber + 1}: ${errors}`]
  }
  let filteredErrors = errors
    .filter((str) => str.includes(`row #${rowNumber + 1}:`))
    .map((error) => `${error.split(`row #${rowNumber + 1}: `)[1]}, `)
  checkArrayPresence(filteredErrors) && filteredErrors.unshift(`Row #${rowNumber + 1}: `)
  return filteredErrors.length > 0 ? filteredErrors : ''
}

export const extractNumber = (str) => {
  const match = str.match(/\[(\d+)\]/)
  return match ? parseInt(match[1], 10) : null
}

export const validateEmailFormat = (values) => {
  let regex = EMAIL_FORMAT
  let validEmail = new RegExp(regex)

  return validEmail.test(values)
}

export const downloadSampleCsv = (oldHeaders, filename) => {
  let headers = Object.values(oldHeaders)
    .filter((value) => value.hasOwnProperty('label'))
    .map((value) => value.label)
  const csvData = headers.join(',') + '\n'
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export const getAmsOrigin = (companySubdomain) => {
  const isProdEnv = process.env.NODE_ENV === 'production'

  return `${isProdEnv ? 'https://' : 'http://'}${companySubdomain}.${
    isProdEnv ? 'maplehr' : 'localhost'
  }.io${isProdEnv ? '' : ':' + AMS_PORT}`
}

export const handleModalOffset = (sidebarOpen, companySubdomain) => {
  const amsIframe = document.getElementById(AMS_IFRAME_ID)

  amsIframe.contentWindow.postMessage(
    {
      message: {
        height: window.innerHeight,
        scrollPosition: Math.floor(document.documentElement.scrollTop),
        show: webView(),
        sidebarOpen
      },
      action: 'setModalOffset'
    },
    getAmsOrigin(companySubdomain)
  )
  document.body.style.overflow = webView() ? 'hidden' : 'auto'
}

export const handleReceiveMessage = (event, navigate, setShowBlurOverlay, sidebarOpen, companySubdomain) => {
  const amsOrigin = getAmsOrigin(companySubdomain)

  if (event.origin === amsOrigin) {
    const {
      data: { path, action, message }
    } = event
    const amsIframe = document.getElementById(AMS_IFRAME_ID)

    switch (action) {
      case 'navigate':
        return navigate(path)
      case 'showBlurOverlay':
        document.body.style.overflow = webView() ? 'hidden' : 'auto'
        amsIframe.contentWindow.postMessage(
          {
            message: {
              height: window.innerHeight,
              scrollPosition: Math.floor(document.documentElement.scrollTop),
              show: webView(),
              sidebarOpen
            },
            action: 'setModalOffset'
          },
          amsOrigin
        )
        return setShowBlurOverlay(true)
      case 'hideBlurOverlay':
        amsIframe.contentWindow.postMessage(
          {
            message: {
              show: false
            },
            action: 'hideModalOffset'
          },
          amsOrigin
        )
        document.body.style.overflow = 'auto'
        return setShowBlurOverlay(false)
      case 'pushState':
      case 'replaceState':
        return window.history[action](null, '', path)
      case 'error':
      case 'success':
        return toastr[action](message)
      case 'setHeight': {
        amsIframe.style.height = `${message}px`
        return
      }
    }
  }
}

export const setLocalStorageItems = (key, value) => localStorage.setItem(key, value)

export const getLocalStorageItems = (key) => localStorage.getItem(key)

export const removeLocalStorageItems = (key) => localStorage.removeItem(key)

export const dynamicApprovalOptions = [
  { label: 'Dynamic Approvals', value: 'dynamic_approvals_required' },
  { label: 'Lead Approval', value: 'lead_approval_required' },
  { label: 'PM Approval', value: 'pm_approval_required' }
]

export const checkHasAccessToUnits = (permissions) =>
  invitePeople(permissions) || editOtherProfile(permissions) || isAccountOwner(permissions)

export const getRankOptions = (allTitleRanks) => {
  let uniqueTitleRanksMap = new Map()

  allTitleRanks?.forEach((title) => {
    const titleRank = title.title_rank
    if (titleRank && titleRank.rank !== undefined) {
      uniqueTitleRanksMap.set(titleRank.rank, titleRank)
    }
  })

  uniqueTitleRanksMap = Array.from(uniqueTitleRanksMap.values())
  uniqueTitleRanksMap.sort((a, b) => a.rank - b.rank)
  return uniqueTitleRanksMap
}

export const getLevelOptions = (titles, ranks) =>
  titles?.filter((title) => title?.title_rank?.rank !== undefined && ranks.includes(title?.title_rank.rank)) ?? []

export const avatarImageProps = (offboarded = false) => {
  return { loading: 'lazy', style: { objectFit: 'contain', opacity: offboarded ? '0.5' : '1' } }
}

export const tabHeading = (locationPathName) => {
  const breadCrumbs = {
    '/dashboard': {
      title: 'Dashboard',
      subtitle: null
    },
    '/people/new-bulk-users': {
      title: 'Create Bulk Users',
      subtitle: 'Upload your .CSV file to add bulk users'
    },
    '/people/operational-staff/new-bulk-operational-staff': {
      title: 'Create Bulk Operational Staff',
      subtitle: 'Upload your .CSV file to add bulk operational staff'
    },
    '/hr-dashboard': {
      title: 'Pre-Onboarding',
      subtitle: 'Manage Pre-Onboarding Users.'
    },
    '/hr-dashboard/verification': {
      title: 'Verification',
      subtitle: 'Verify Records of Pre-onboarding users to convert them into employees.'
    },
    '/hr-dashboard/employee-offboarding': {
      title: 'Employee Offboarding',
      subtitle: 'Employee Offboarding tab.'
    },
    '/hr-dashboard/approvals': {
      title: 'Announcement Approvals',
      subtitle: 'List of announcement Approvals for Promotions.'
    },
    '/workflow-categories': {
      title: 'Worfklows',
      subtitle: 'Workflow Is A Repeatable Process That Consists Of Series Of Tasks That Generally Need To Be Completed.'
    },
    '/workflow-categories/4': {
      title: 'Preonboarding',
      subtitle: 'Preonboarding'
    },
    '/all-tasks': {
      title: 'All Tasks',
      subtitle: null
    },
    '/inventory/moveable/overview': {
      title: 'Overview',
      subtitle:
        'Gain real-time insights into your entire movable inventory module with comprehensive dashboard analytics'
    },
    '/inventory/moveable/manage-category': {
      title: 'Manage Category',
      subtitle: 'Create, edit, and archive categories to manage inventory with precision.'
    },
    '/inventory/moveable/manage-office': {
      title: 'Manage Office',
      subtitle:
        'Streamline office management by easily creating, editing, and deleting offices & organize asset location accordingly.'
    },
    '/inventory/moveable/manage-vendor': {
      title: 'Manage Vendor',
      subtitle:
        'Manage vendor profiles. Maintain a seamless workflow by effectively managing your relationships with vendors.'
    },
    '/inventory/moveable/manage-item': {
      title: 'Manage Item',
      subtitle: ' Manage items of inventory.'
    },
    '/inventory/moveable/resell-item': {
      title: 'Resell Equipment',
      subtitle: ' Manage resell equipment by creating, editing, and exporting relevant details. '
    },
    '/inventory/moveable/repair-item': {
      title: 'Repair Equipment',
      subtitle: 'Manage Repair Equipment By Creating, Editing, And Exporting Relevant Details.'
    },
    '/inventory/moveable/logs': {
      title: 'Item Logs',
      subtitle: 'Manage Item Logs By Creating, Editing, And Exporting Relevant Details.'
    },
    '/departments/new': {
      title: 'New Department',
      subtitle: null
    },
    '/application-settings/projects': {
      title: 'Project Settings',
      subtitle: null
    },
    '/application-settings/claims': {
      title: 'Claim Types',
      subtitle: null
    },
    '/application-settings/leaves': {
      title: 'Leave Settings',
      subtitle: null
    },
    '/application-settings/d-nars': {
      title: 'D-NARS Settings',
      subtitle: null
    },
    '/application-settings/ha-ops': {
      title: 'HA-Operations',
      subtitle: 'Configure the Settings for HA Modules.'
    },
    '/application-settings/ha-ops/pre-onboarding-settings/manage-invite': {
      title: 'Pre-Onboarding Settings',
      subtitle: 'Manage pre-onboarding portal for users.'
    },
    '/application-settings/ha-ops/edit-settings': {
      title: 'Edit Settings',
      subtitle: 'These users will be notified for montly extra working days and weekly over due probational employees.'
    },
    '/application-settings/ha-ops/probation-settings': {
      title: 'Probation Settings',
      subtitle:
        'These users will be notified for receiving email regarding list of employees with upcoming work anniversary.'
    },
    '/application-settings/ha-ops/review-settings': {
      title: 'Add Review Settings',
      subtitle:
        'These users will be notified for receiving email regarding list of employees with upcoming work anniversary.'
    },
    '/application-settings/ha-ops/announcement-settings': {
      title: 'Announcement Setting',
      subtitle: 'List of announcements for employees.'
    },
    '/people': {
      title: 'People',
      subtitle: null
    },
    '/inventory': {
      title: 'inventory',
      subtitle:
        'Manage movable and fixed inventory with features like categories, vendors, reselling, repairs, and record-keeping.'
    },
    '/tickets': {
      title: 'tickets',
      subtitle: null
    },
    '/reports': {
      title: 'reports',
      subtitle: 'Latest statistics & Summary'
    },
    '/ams': {
      title: 'Attendance Management System',
      subtitle: null
    },
    '/performance': {
      title: 'Library',
      subtitle: null
    },
    '/leaves': {
      title: 'leaves',
      subtitle: null
    },
    '/project': {
      title: 'Projects',
      subtitle: null
    },
    '/claims': {
      title: 'claims summary',
      subtitle: null
    },
    '/faqs': {
      title: 'FAQs',
      subtitle: 'List of frequently asked questions (FAQs).'
    },
    '/dnar': {
      title: 'D-NARS',
      subtitle: null
    },
    '/integrations': {
      title: 'integrations',
      subtitle: 'Connect with external services that you already use.'
    },
    '/departments': {
      title: 'Department',
      subtitle: 'Efficiently manage your organizational departments here.'
    },
    '/roles': {
      title: 'All Roles',
      subtitle: 'Here is a list of all roles for the company'
    },
    '/admin-panel': {
      title: 'D-NARS Admin Panel',
      subtitle: 'Admin related tasks for Super Users'
    },
    '/calendar': {
      title: 'calendar',
      subtitle: 'Stay organized and updated on leaves and holidays of your team and subordinates with our HRMS calendar'
    },
    '/feedbacks': {
      title: 'feedbacks',
      subtitle: 'Feedback and Suggestions.'
    },
    '/subscriptions/live-counter': {
      title: 'Food Subscription Employees',
      subtitle: 'List of Active Subscriptions with live counts'
    },
    '/subscriptions/verification-panel': {
      title: 'Subscription Verification Panel',
      subtitle: 'List of Employees who wants to, and are availing lunch and dinner services.'
    },
    '/settings': {
      title: 'Application Settings',
      subtitle: 'Set application settings here.'
    },
    '/hr-dashboard/probations': {
      title: 'HA-Operations',
      subtitle: null
    },
    '/application-settings/inventory': {
      title: 'Inventory Settings',
      subtitle: null
    },
    '/application-settings/office-mgmt': {
      title: 'Office Management',
      subtitle: null
    },
    '/application-settings/company': {
      title: 'Company Settings',
      subtitle: null
    },
    '/application-settings/business-units': {
      title: 'Business Units',
      subtitle: null
    },
    '/application-settings/time-mgmt': {
      title: 'Time Tracker Settings',
      subtitle: null
    },
    '/application-settings/dynamic-forms': {
      title: 'Dynamic Forms',
      subtitle: 'Dynamic forms for surveys, feedbacks, evaluation and to-do list'
    },
    '/application-settings/document-settings': {
      title: 'User Form Settings',
      subtitle: null
    },
    '/hr-dashboard/creation-logs': {
      title: 'User Creation Logs',
      subtitle: 'This will display logs of users scheduled to be created through the Maple-ATS integration.'
    },
    '/application-settings/tickets': {
      title: 'Ticket Categories',
      subtitle: 'List of Tickets currently created for employees'
    },
    '/inventory/moveable/manage-item/archived': {
      title: 'Archived Inventory',
      subtitle: null
    },
    '/inventory/fixed/manage-item/archived': {
      title: 'Archived Inventory',
      subtitle: null
    },
    '/subscriptions/reports': {
      title: 'Reports',
      subtitle: null
    }
  }

  const longestMatchPath = findLongestPrefixMatch(locationPathName, breadCrumbs)
  const breadCrumbData = breadCrumbs[longestMatchPath]

  if (breadCrumbData) {
    return {
      title: breadCrumbData.title,
      subtitle: breadCrumbData.subtitle
    }
  }

  return {
    title: '',
    subtitle: ''
  }
}

const findLongestPrefixMatch = (path, breadCrumbs) => {
  let longestMatch = ''
  for (const key of Object.keys(breadCrumbs)) {
    if (path.startsWith(key) && key.length > longestMatch.length) {
      longestMatch = key
    }
  }
  return longestMatch
}

export const breadCrumbsTab = (locationPathName, props) => {
  const breadCrumbs = {
    '/workflow-categories': {
      items: [['WorkFlow Category', '/workflow-categories']]
    },
    '/workflow-categories/new': {
      items: [['WorkFlow Category', '/workflow-categories']]
    },
    '/workflow-categories/': {
      items: [
        ['WorkFlow Category', '/workflow-categories'],
        [`${props.breadCrumbs?.breadCrumbs?.name}`, `/workflow-categories/${props.breadCrumbs?.breadCrumbs?.id}`]
      ]
    },
    'workflow-categories/:id/workflows': {
      items: [
        ['WorkFlow Category', '/workflow-categories'],
        [`${props.breadCrumbs?.breadCrumbs?.name}`, `/workflow-categories/${props.breadCrumbs?.breadCrumbs?.id}`],
        ['All Tasks']
      ]
    },
    '/all-tasks': {
      items: [['WorkFlow Category', '/workflow-categories']]
    },
    '/application-settings/tickets': {
      items: [
        ['Admin Settings', '/application-settings/tickets'],
        ['Tickets', '/application-settings/tickets'],
        ['Ticket Settings']
      ]
    },
    '/application-settings/tickets/categories/create': {
      items: [['Ticket Categories', '/application-settings/tickets/categories'], ['Create Categories']]
    },
    '/application-settings/leaves/rules/auto_apply_policies/new': {
      items: [['Admin Setting', '/application-settings/leaves/rules/auto_apply_policies'], ['New Auto Leave']]
    },
    '/application-settings/dynamic-forms/new': {
      items: [['Dynamic Forms', '/application-settings/dynamic-forms'], ['New Form']]
    },
    '/application-settings/dynamic-forms/edit': {
      items: [['Dynamic Forms', '/application-settings/dynamic-forms'], ['Edit Form']]
    },
    '/application-settings/document-settings/identity-document-settings': {
      items: [['User Flow Settings', '/application-settings/document-settings']]
    },
    '/hr-dashboard/new': {
      items: [['Onboarding', '/hr-dashboard'], ['Create User']]
    },
    '/hr-dashboard/edit': {
      items: [['Onboarding', '/hr-dashboard'], ['Edit User']]
    },
    '/inventory/moveable': {
      items: [['Inventory', '/inventory'], ['Moveable Inventory']]
    },
    '/inventory/moveable/manage-item': {
      items: [
        ['Inventory', '/inventory'],
        ['Moveable Inventory', '/inventory/moveable/manage-item']
      ]
    },
    '/inventory/moveable/manage-item/new': {
      items: [
        ['Inventory', '/inventory'],
        ['Moveable Inventory', '/inventory/moveable/manage-item'],
        ['Manage Item', '/inventory/moveable/manage-item'],
        ['Create Item']
      ]
    },
    '/inventory/fixed': {
      items: [['Inventory', '/inventory'], ['Fixed Inventory']]
    },
    '/inventory/fixed/manage-item/new': {
      items: [
        ['Inventory', '/inventory'],
        ['Fixed Inventory', '/inventory/fixed/manage-item'],
        ['Manage Item', '/inventory/fixed/manage-item'],
        ['Create Item']
      ]
    },
    '/people/new': {
      items: [['People', '/people'], ['Create User']]
    },
    '/people/new-bulk-users': {
      items: [['People', '/people'], ['Add Bulk Users']]
    },
    '/people/support-staff/new': {
      items: [['People', '/people'], ['New Operation Staff']]
    },
    '/people/operational-staff/new-bulk-operational-staff': {
      items: [['People', '/people'], ['Add Bulk Operational Staff']]
    },
    '/project/new': {
      items: [['View Projects', '/projects']]
    },
    '/project': {
      items: [['View Projects', '/projects']]
    },
    '/departments/new': {
      items: [['Departments', '/departments']]
    },
    '/departments/edit': {
      items: [['Departments', '/departments'], ['Edit department']]
    },
    '/application-settings/ha-ops/pre-onboarding-settings/manage-invite': {
      items: [['HA Operations', '/application-settings/ha-ops'], ['Manage Invite']]
    },
    '/application-settings/ha-ops/pre-onboarding-settings/manage-pages': {
      items: [['HA Operations', '/application-settings/ha-ops'], ['Manage Pages']]
    },
    '/application-settings/ha-ops/edit-settings': {
      items: [['HA Operations', '/application-settings/ha-ops'], ['Edit Settings']]
    },
    '/application-settings/ha-ops/probation-settings': {
      items: [['HA Operations', '/application-settings/ha-ops'], ['Probation Settings']]
    },
    '/application-settings/ha-ops/review-settings': {
      items: [['HA Operations', '/application-settings/ha-ops'], ['Review Settings']]
    },
    '/application-settings/ha-ops/review-settings/edit': {
      items: [
        ['HA Operations', '/application-settings/ha-ops'],
        ['Review Settings', '/application-settings/ha-ops/review-settings'],
        ['Edit Settings']
      ]
    },
    '/application-settings/ha-ops/review-settings/create': {
      items: [
        ['HA Operations', '/application-settings/ha-ops'],
        ['Review Settings', '/application-settings/ha-ops/review-settings'],
        ['Create Settings']
      ]
    },
    '/application-settings/ha-ops/announcement-settings': {
      items: [['HA Operations', '/application-settings/ha-ops'], ['Announcment Settings']]
    },
    '/application-settings/ha-ops/email-list-settings': {
      items: [['HA Operations', '/application-settings/ha-ops'], ['User Email Settings']]
    },
    '/hr-dashboard/verify/oid': {
      items: [['Verification', '/hr-dashboard/verification'], ['Verify User']]
    },
    '/probation': {
      items: [['Probations', '/hr-dashboard/probations'], [`${props.breadCrumbs?.breadCrumbs?.name}`]]
    },
    '/review': {
      items: [['Reviews', '/hr-dashboard/reviews'], [`${props.breadCrumbs?.breadCrumbs?.name}`]]
    },
    '/inventory/moveable/manage-category/': {
      items: [
        ['Categories', '/inventory/moveable/manage-category'],
        [
          `${props.breadCrumbs?.breadCrumbs?.name}`,
          `/inventory/moveable/manage-category/${props.breadCrumbs?.breadCrumbs?.id}/sub_categories`
        ]
      ]
    },
    '/inventory/fixed/manage-category/': {
      items: [
        ['Categories', '/inventory/fixed/manage-category'],
        [
          `${props.breadCrumbs?.breadCrumbs?.name}`,
          `/inventory/fixed/manage-category/${props.breadCrumbs?.breadCrumbs?.id}/sub_categories`
        ]
      ]
    }
  }

  const longestMatchPath = findLongestPrefixMatch(locationPathName, breadCrumbs)

  const breadCrumbData = breadCrumbs[longestMatchPath]

  if (breadCrumbData) {
    return breadCrumbData.items
  }

  return []
}
